const stores = {
  state: {
    cssExtracted: {},
    appStrings: {},
    filtersConfig: {},
    selectedFilters: {},
    selectedCityData: {},
    filtersResult: {},
    currencyUnit: "₹",
    mileageMetricUnit: "",
    selectedCarId: "",
    selectedCarDetails: null,
    topScoreThreshold: null,
    transitionInProgress: false,
    activeTransitionName: false,
    selectedSortKey: "",
    citiesConfig: [],
    sortConfig: {},
    selectedCuratedTag: "",
    selectedSearchItem: "",
    staticConfig: {},
    chatbotViewShown: false,
    sellerRegistrationData: {},
    isStickyCtaPage: false,
    selectedCarData: {},
    showChatbotIcon: true,
    orderId: null,
    isBuyerFlow: false,
    userLoggedIn: false,
    userDetails: {},
    sellerUserState: "",
    currentSellerInventoryId: "",
    isSellerFlow: false,
    isBookingHistory: false,
    virtualAccountDetails: {},
    stickyTestDriveData: {},
    softFiltersConfig: [],
    filteredListingsData: {},
    filteredListingsUrl: "",
    listingsScrollPos: 0,
    isStickyCtaAndTxtPage: false,
    resetSliderToInitial: "",
    campaignClickedDetails: {},
    couponDetails: null,
    savedAddressesList: [],
    testMapPin: null,
    lastRecentlyViewedPageData: {},
    isDealer: false,
    dealerDetails: null,
    isAllImagesLoaded: false,
    imagesSortedTime: [],
    isZenDeskScriptLoaded: false,
    enableBottomNav: false,
    setSellerEntryData: {},
    bottomNavClickedData: null,
    bottomNavActive: false,
    bottomNavSpaceOccupied: null,
    retailOutletStatus: null,
    filtersSelectedSource: {},
    webSellerConfig: null,
    selectedCollectionId: ""
  },
  getters: {
    getSelectedFilters: state => {
      return state.selectedFilters;
    },
    getFiltersConfig: state => {
      return state.filtersConfig;
    },
    getRegistrationData: state => {
      let localReg = null;
      try {
        localReg = JSON.parse(localStorage.getItem("sellerRegistrationData"));
      } catch (err) {
        localReg = null;
      }

      if (localReg) {
        return localReg;
      } else {
        return state.sellerRegistrationData;
      }
    }
  },
  mutations: {
    set_transition(state, data) {
      state.transitionInProgress = data;
    },
    setTransitionName(state, data) {
      state.activeTransitionName = data;
    },
    set_css(state, { id, css }) {
      state.cssExtracted[id] = css;
    },
    set_app_strings(state, data) {
      state.appStrings = data;
    },
    set_selected_filters(state, data) {
      state.selectedFilters = data;
    },
    set_selected_car_id(state, data) {
      state.selectedCarId = data;
    },
    set_selected_car_details(state, data) {
      state.selectedCarDetails = data;
    },
    set_location_timer(state, data) {
      state.setLocationTimer = data;
    },
    set_brands_filters(state, data) {
      state.brandsFilters = data;
    },
    set_filters_config(state, data) {
      state.filtersConfig = data;
    },
    set_filters_result(state, data) {
      state.filtersResult = data;
    },
    set_selected_city_data(state, data) {
      state.selectedCityData = data;
      //localStorage.setItem("city", data.name);
    },
    set_currency_unit(state, data) {
      state.currencyUnit = data;
    },
    set_mileage_unit(state, data) {
      state.mileageMetricUnit = data;
    },
    set_top_score_threshold(state, data) {
      state.topScoreThreshold = data;
    },
    set_selected_sort_key(state, data) {
      state.selectedSortKey = data;
    },
    set_cities_config(state, data) {
      let sortedData =
        (data &&
          data.sort((a, b) => a.display_name.localeCompare(b.display_name))) ||
        [];
      state.citiesConfig = sortedData;
    },
    set_sort_config(state, data) {
      state.sortConfig = data;
    },
    set_order_id(state, data) {
      state.orderId = data;
    },
    set_selected_curated_tag(state, data) {
      state.selectedCuratedTag = data;
    },
    set_selected_search_item(state, data) {
      state.selectedSearchItem = data;
    },
    set_static_config(state, data) {
      state.staticConfig = data;
    },
    set_chatbot_view_shown(state, data) {
      state.chatbotViewShown = data;
    },
    set_seller_registration_data(state, data) {
      state.sellerRegistrationData = data;
      // localStorage.setItem("sellerRegistrationData", JSON.stringify(data));
    },
    set_is_sticky_cta_page(state, data) {
      state.isStickyCtaPage = data;
    },
    set_is_buyer(state, data) {
      state.isBuyerFlow = data;
    },
    set_is_seller(state, data) {
      state.isSellerFlow = data;
    },
    set_selected_map_pin(state, data) {
      state.selectedMapPin = data;
    },
    set_test_map_pin(state, data) {
      state.testMapPin = data;
    },
    set_selected_car_data(state, data) {
      state.selectedCarData = data;
    },
    set_show_chatbot_icon(state, data) {
      state.showChatbotIcon = data;
    },
    set_delivery_address(state, data) {
      state.deliveryAddress = data;
    },
    set_test_delivery_address_obj(state, data) {
      state.testDeliveryAddressObj = data;
    },
    set_saved_addresses_list(state, data) {
      state.savedAddressesList = data;
    },
    set_billing_address(state, data) {
      state.billingAddress = data;
    },
    set_selected_time(state, data) {
      state.selectedTime = data;
    },
    set_user_logged_in_status(state, data) {
      state.userLoggedIn = data;
    },
    set_user_details(state, data) {
      state.userDetails = data;
    },
    set_seller_user_state(state, data) {
      state.sellerUserState = data;
    },
    set_current_seller_inventory_id(state, data) {
      state.currentSellerInventoryId = data;
    },
    set_is_booking_history(state, data) {
      state.isBookingHistory = data;
    },
    set_virtual_account_details(state, data) {
      state.virtualAccountDetails = data;
    },
    set_sticky_data(state, data) {
      state.stickyTestDriveData = data;
    },
    set_soft_filters_config(state, data) {
      state.softFiltersConfig = data;
    },
    set_filtered_listings_data(state, data) {
      state.filteredListingsData = data;
    },
    set_filtered_listings_url(state, data) {
      state.filteredListingsUrl = data;
    },
    set_listings_scroll_position(state, data) {
      state.listingsScrollPos = data;
    },
    set_is_sticky_cta_and_txt_page(state, data) {
      state.isStickyCtaAndTxtPage = data;
    },
    reset_slider_to_initial(state, data) {
      state.resetSliderToInitial = data;
    },
    set_campaign_clicked_details(state, data) {
      state.campaignClickedDetails = data;
    },
    set_coupon_details(state, data) {
      if (data) {
        const couponData = {};
        const coupon =
          (data.promotion_details &&
            data.promotion_details.length &&
            data.promotion_details[0]) ||
          null;
        const pricingInfo = data.pricing_info || null;
        if (coupon) {
          Object.assign(couponData, {
            couponCode: coupon.promo_code || "",
            couponAmount: coupon.promotion_value
              ? new Number(coupon.promotion_value)
              : "",
            couponExpiry: coupon.expiration
              ? new Number(coupon.expiration)
              : "",
            finalCarPrice: pricingInfo.final_price
              ? new Number(pricingInfo.final_price)
              : "",
            carPrice:
              state.selectedCarDetails &&
              state.selectedCarDetails.on_screen_price,
            promotionId: data.promotion_id || null,
            reservationAmount: pricingInfo.reservation_amount || ""
          });
          state.couponDetails = couponData;
        } else {
          state.couponDetails = null;
        }
      } else {
        state.couponDetails = null;
      }
    },
    set_last_recently_viewed_page(state, data) {
      state.lastRecentlyViewedPageData = data;
    },
    set_is_dealer(state, data) {
      state.isDealer = data;
    },
    set_dealer_details(state, data) {
      state.dealerDetails = data;
    },
    set_all_images_loaded(state, data) {
      state.isAllImagesLoaded = data;
    },
    images_sorted_time(state, data) {
      state.imagesSortedTime = data;
    },
    set_seller_entry_data(state, data) {
      state.setSellerEntryData = data;
    },
    set_zendesk_script_loaded(state, data) {
      state.isZenDeskScriptLoaded = data;
    },
    set_enable_bottom_nav(state, data) {
      state.enableBottomNav = data;
    },
    set_bottom_nav_clicked_data(state, data) {
      state.bottomNavClickedData = data;
    },
    set_bottom_nav_active(state, data) {
      state.bottomNavActive = data;
    },
    set_bottom_nav_space_occupied(state, data) {
      state.bottomNavSpaceOccupied = data;
    },
    set_retail_outlet_status(state, data) {
      state.retailOutletStatus = data;
    },
    set_filters_selected_source(state, data) {
      state.filtersSelectedSource = data;
    },
    set_web_seller_config(state, data) {
      state.webSellerConfig = data;
    },
    set_selected_collection_id(state, data) {
      state.selectedCollectionId = data;
    }
  },
  actions: {
    SET_TRANSITION: ({ commit }, data) => {
      commit("set_transition", data);
    },
    SET_TRANSITION_NAME: ({ commit }, data) => {
      commit("setTransitionName", data);
    },
    SET_CSS_ATTRIBUTE: ({ commit }, data) => {
      commit("set_css", data);
    },
    SET_APP_STRINGS: ({ commit }, data) => {
      commit("set_app_strings", data);
    },
    SET_SELECTED_FILTERS: ({ commit }, data) => {
      commit("set_selected_filters", data);
    },
    SET_SELECTED_CAR_ID: ({ commit }, data) => {
      commit("set_selected_car_id", data);
    },
    SET_SELECTED_CAR_DETAILS: ({ commit }, data) => {
      commit("set_selected_car_details", data);
    },
    SET_LOCATION_TIMER: ({ commit }, data) => {
      commit("set_location_timer", data);
    },
    SET_BRANDS_FILTERS: ({ commit }, data) => {
      commit("set_brands_filters", data);
    },
    SET_FILTERS_CONFIG: ({ commit }, data) => {
      commit("set_filters_config", data);
    },
    SET_FILTERS_RESULT: ({ commit }, data) => {
      commit("set_filters_result", data);
    },
    SET_SELECTED_CITY_DATA: ({ commit }, data) => {
      commit("set_selected_city_data", data);
    },
    SET_CURRENCY_UNIT: ({ commit }, data) => {
      commit("set_currency_unit", data);
    },
    SET_MILEAGE_UNIT: ({ commit }, data) => {
      commit("set_mileage_unit", data);
    },
    SET_TOP_SCORE_THRESHOLD: ({ commit }, data) => {
      commit("set_top_score_threshold", data);
    },
    SET_SELECTED_SORT_KEY: ({ commit }, data) => {
      commit("set_selected_sort_key", data);
    },
    SET_CITIES_CONFIG: ({ commit }, data) => {
      commit("set_cities_config", data);
    },
    SET_SORT_CONFIG: ({ commit }, data) => {
      commit("set_sort_config", data);
    },
    SET_ORDER_ID: ({ commit }, data) => {
      commit("set_order_id", data);
    },
    SET_SELECTED_CURATED_TAG: ({ commit }, data) => {
      commit("set_selected_curated_tag", data);
    },
    SET_SELECTED_SEARCH_ITEM: ({ commit }, data) => {
      commit("set_selected_search_item", data);
    },
    SET_STATIC_CONFIG: ({ commit }, data) => {
      commit("set_static_config", data);
    },
    SET_CHATBOT_VIEW_SHOWN: ({ commit }, data) => {
      commit("set_chatbot_view_shown", data);
    },
    SET_SELLER_REGISTRATION_DATA: ({ commit }, data) => {
      commit("set_seller_registration_data", data);
    },
    SET_IS_STICKY_CTA_PAGE: ({ commit }, data) => {
      commit("set_is_sticky_cta_page", data);
    },
    SET_IS_BUYER: ({ commit }, data) => {
      commit("set_is_buyer", data);
    },
    SET_IS_SELLER: ({ commit }, data) => {
      commit("set_is_seller", data);
    },
    SET_SELECTED_MAP_PIN: ({ commit }, data) => {
      commit("set_selected_map_pin", data);
    },
    SET_TEST_MAP_PIN: ({ commit }, data) => {
      commit("set_test_map_pin", data);
    },
    SET_SELECTED_CAR_DATA: ({ commit }, data) => {
      commit("set_selected_car_data", data);
    },
    SET_SHOW_CHATBOT_ICON: ({ commit }, data) => {
      commit("set_show_chatbot_icon", data);
    },
    SET_DELIVERY_ADDRESS: ({ commit }, data) => {
      commit("set_delivery_address", data);
    },
    SET_TEST_DELIVERY_ADDRESS_OBJ: ({ commit }, data) => {
      commit("set_test_delivery_address_obj", data);
    },
    SET_SAVED_ADDRESSES_LIST: ({ commit }, data) => {
      commit("set_saved_addresses_list", data);
    },
    SET_BILLING_ADDRESS: ({ commit }, data) => {
      commit("set_billing_address", data);
    },
    SET_SELECTED_TIME: ({ commit }, data) => {
      commit("set_selected_time", data);
    },
    SET_USER_LOGGED_IN_STATUS: ({ commit }, data) => {
      commit("set_user_logged_in_status", data);
    },
    SET_USER_DETAILS: ({ commit }, data) => {
      commit("set_user_details", data);
    },
    SET_SELLER_USER_STATE: ({ commit }, data) => {
      commit("set_seller_user_state", data);
    },
    SET_CURRENT_SELLER_INVENTORY_ID: ({ commit }, data) => {
      commit("set_current_seller_inventory_id", data);
    },
    SET_IS_BOOKING_HISTORY: ({ commit }, data) => {
      commit("set_is_booking_history", data);
    },
    SET_VIRTUAL_ACCOUNT_DETAILS: ({ commit }, data) => {
      commit("set_virtual_account_details", data);
    },
    SET_STICKY_DATA: ({ commit }, data) => {
      commit("set_sticky_data", data);
    },
    SET_SOFT_FILTERS_CONFIG: ({ commit }, data) => {
      commit("set_soft_filters_config", data);
    },
    SET_FILTERED_LISTINGS_DATA: ({ commit }, data) => {
      commit("set_filtered_listings_data", data);
    },
    SET_FILTERED_LISTINGS_URL: ({ commit }, data) => {
      commit("set_filtered_listings_url", data);
    },
    SET_LISTINGS_SCROLL_POSITION: ({ commit }, data) => {
      commit("set_listings_scroll_position", data);
    },
    SET_IS_STICKY_CTA_AND_TXT_PAGE: ({ commit }, data) => {
      commit("set_is_sticky_cta_and_txt_page", data);
    },
    RESET_SLIDER_TO_INITIAL: ({ commit }, data) => {
      commit("reset_slider_to_initial", data);
    },
    SET_CAMPAIGN_CLICKED_DETAILS: ({ commit }, data) => {
      commit("set_campaign_clicked_details", data);
    },
    SET_COUPON_DETAILS: ({ commit }, data) => {
      commit("set_coupon_details", data);
    },
    SET_LAST_RECENTLY_VIEWED_PAGE: ({ commit }, data) => {
      commit("set_last_recently_viewed_page", data);
    },
    SET_IS_DEALER: ({ commit }, data) => {
      commit("set_is_dealer", data);
    },
    SET_DEALER_DETAILS: ({ commit }, data) => {
      commit("set_dealer_details", data);
    },
    SET_ALL_IMAGES_LOADED: ({ commit }, data) => {
      commit("set_all_images_loaded", data);
    },
    IMAGES_SORTED_TIME: ({ commit }, data) => {
      commit("images_sorted_time", data);
    },
    SET_SELLER_ENTRY_DATA: ({ commit }, data) => {
      commit("set_seller_entry_data", data);
    },
    SET_ZENDESK_SCRIPT_LOADED: ({ commit }, data) => {
      commit("set_zendesk_script_loaded", data);
    },
    SET_ENABLE_BOTTOM_NAV: ({ commit }, data) => {
      commit("set_enable_bottom_nav", data);
    },
    SET_BOTTOM_NAV_CLICKED_DATA({ commit }, data) {
      commit("set_bottom_nav_clicked_data", data);
    },
    SET_BOTTOM_NAV_ACTIVE({ commit }, data) {
      commit("set_bottom_nav_active", data);
    },
    SET_BOTTOM_NAV_SPACE_OCCUPIED({ commit }, data) {
      commit("set_bottom_nav_space_occupied", data);
    },
    SET_RETAIL_OUTLET_STATUS({ commit }, data) {
      commit("set_retail_outlet_status", data);
    },
    SET_FILTERS_SELECTED_SOURCE({ commit }, data) {
      commit("set_filters_selected_source", data);
    },
    SET_WEB_SELLER_CONFIG({ commit }, data) {
      commit("set_web_seller_config", data);
    },
    SET_SELECTED_COLLECTION_ID({ commit }, data) {
      commit("set_selected_collection_id", data);
    }
  }
};
if (typeof exports === "object" && typeof module !== "undefined") {
  module.exports = stores;
} else {
  window.store = new Vuex.Store(stores);

  /* Set the store with the app strings received in the serverData object of html */
  window.store.dispatch("SET_APP_STRINGS", window.serverData.appStrings);
}
